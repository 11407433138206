import React from 'react';
import { Layout } from '../components/index';
import { Box, Flex, Button, Heading, Container } from '@chakra-ui/react';

const clientToolsPage = () => {
  return (
    <Layout>
      <Container maxW="container.xl">
        <Box p={6} minH="400px">
          <Heading as="h1" size="3xl" fontWeight="light" mb={4} mt={8}>
            Recent Clients
          </Heading>
          <Heading as="h5" color="brand.flare" fontWeight="extrabold">
            Over 35 years of proven industry knowledge&nbsp;and&nbsp;expertise
          </Heading>
        </Box>
      </Container>
      <Container maxW="container.xl">
        <Box p={6} my={8} minH="400px">
          <Heading as="h4" size="2xl" fontWeight="light">
            Health Systems and Hospitals
          </Heading>
        </Box>
        <Box p={6} my={8} minH="400px">
          <Heading as="h4" size="2xl" fontWeight="light">
            Medical Groups and IPAs
          </Heading>
        </Box>
      </Container>
    </Layout>
  );
};

export default clientToolsPage;
